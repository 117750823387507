<template>
  <div class="stage-list-description">
    <div v-if="stage == null" class="stage-list-description-operation">
      <Icon name="gestureSwipe" width="160" height="52" color="white02" />
      <p class="stage-list-description-operation__text">
        左右にスワイプしてステージを見てみましょう<br />
        数字をタップでステージ情報を確認できます
      </p>
    </div>
    <MileStageCard v-else :stage="stage" />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  components: {
    MileStageCard: () => import('@/components/mileStageList/MileStageCard.vue')
  },

  props: {
    stage: {
      type: Number,
      default: null
    }
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.stage-list-description {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 2.4rem 1.6rem;
  pointer-events: none;
  user-select: none;

  @include mixin.mq() {
    padding: 0 1.4rem 1.2rem;
  }
}

.stage-list-description-operation {
  text-align: center;
  padding-bottom: 3rem;
}

.stage-list-description-operation__text {
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.5;
  color: variables.$white02;
  margin-bottom: 0;
}
</style>
