var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stage-list-description" },
    [
      _vm.stage == null
        ? _c(
            "div",
            { staticClass: "stage-list-description-operation" },
            [
              _c("Icon", {
                attrs: {
                  name: "gestureSwipe",
                  width: "160",
                  height: "52",
                  color: "white02",
                },
              }),
              _vm._m(0),
            ],
            1
          )
        : _c("MileStageCard", { attrs: { stage: _vm.stage } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "stage-list-description-operation__text" }, [
      _vm._v(" 左右にスワイプしてステージを見てみましょう"),
      _c("br"),
      _vm._v(" 数字をタップでステージ情報を確認できます "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }