import { render, staticRenderFns } from "./FloatingBottomArea.vue?vue&type=template&id=06d29bd4&scoped=true&"
import script from "./FloatingBottomArea.vue?vue&type=script&lang=js&"
export * from "./FloatingBottomArea.vue?vue&type=script&lang=js&"
import style0 from "./FloatingBottomArea.vue?vue&type=style&index=0&id=06d29bd4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06d29bd4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2211837242/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('06d29bd4')) {
      api.createRecord('06d29bd4', component.options)
    } else {
      api.reload('06d29bd4', component.options)
    }
    module.hot.accept("./FloatingBottomArea.vue?vue&type=template&id=06d29bd4&scoped=true&", function () {
      api.rerender('06d29bd4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/mileStageList/FloatingBottomArea.vue"
export default component.exports